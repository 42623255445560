// extracted by mini-css-extract-plugin
export var m0 = "spacing-mod--m-0--02e0c";
export var m1 = "spacing-mod--m-1--ff7a0";
export var m2 = "spacing-mod--m-2--b4518";
export var m3 = "spacing-mod--m-3--9b17f";
export var m4 = "spacing-mod--m-4--a53db";
export var m5 = "spacing-mod--m-5--87072";
export var mAuto = "spacing-mod--m-auto--a7cf0";
export var mLg0 = "spacing-mod--m-lg-0--ae0ae";
export var mLg1 = "spacing-mod--m-lg-1--8423e";
export var mLg2 = "spacing-mod--m-lg-2--34f4c";
export var mLg3 = "spacing-mod--m-lg-3--17e7f";
export var mLg4 = "spacing-mod--m-lg-4--c5a8a";
export var mLg5 = "spacing-mod--m-lg-5--5b1bf";
export var mLgAuto = "spacing-mod--m-lg-auto--697db";
export var mLgN1 = "spacing-mod--m-lg-n1--6c074";
export var mLgN2 = "spacing-mod--m-lg-n2--e426e";
export var mLgN3 = "spacing-mod--m-lg-n3--e8ab6";
export var mLgN4 = "spacing-mod--m-lg-n4--7d5ed";
export var mLgN5 = "spacing-mod--m-lg-n5--8c281";
export var mMd0 = "spacing-mod--m-md-0--ad1b9";
export var mMd1 = "spacing-mod--m-md-1--6350e";
export var mMd2 = "spacing-mod--m-md-2--2e19c";
export var mMd3 = "spacing-mod--m-md-3--ac6a0";
export var mMd4 = "spacing-mod--m-md-4--1d878";
export var mMd5 = "spacing-mod--m-md-5--16deb";
export var mMdAuto = "spacing-mod--m-md-auto--188c9";
export var mMdN1 = "spacing-mod--m-md-n1--0eab2";
export var mMdN2 = "spacing-mod--m-md-n2--f933d";
export var mMdN3 = "spacing-mod--m-md-n3--2fcfc";
export var mMdN4 = "spacing-mod--m-md-n4--a2a5a";
export var mMdN5 = "spacing-mod--m-md-n5--75841";
export var mN1 = "spacing-mod--m-n1--81a30";
export var mN2 = "spacing-mod--m-n2--3a171";
export var mN3 = "spacing-mod--m-n3--53e0c";
export var mN4 = "spacing-mod--m-n4--b37ec";
export var mN5 = "spacing-mod--m-n5--7eb03";
export var mSm0 = "spacing-mod--m-sm-0--fee45";
export var mSm1 = "spacing-mod--m-sm-1--c57de";
export var mSm2 = "spacing-mod--m-sm-2--42ea3";
export var mSm3 = "spacing-mod--m-sm-3--00ba0";
export var mSm4 = "spacing-mod--m-sm-4--a8cbc";
export var mSm5 = "spacing-mod--m-sm-5--832ca";
export var mSmAuto = "spacing-mod--m-sm-auto--37c8a";
export var mSmN1 = "spacing-mod--m-sm-n1--de81b";
export var mSmN2 = "spacing-mod--m-sm-n2--06e3e";
export var mSmN3 = "spacing-mod--m-sm-n3--b4dd1";
export var mSmN4 = "spacing-mod--m-sm-n4--b0e7f";
export var mSmN5 = "spacing-mod--m-sm-n5--f7f06";
export var mXl0 = "spacing-mod--m-xl-0--8c8f3";
export var mXl1 = "spacing-mod--m-xl-1--3ba18";
export var mXl2 = "spacing-mod--m-xl-2--cff80";
export var mXl3 = "spacing-mod--m-xl-3--a8a5b";
export var mXl4 = "spacing-mod--m-xl-4--24087";
export var mXl5 = "spacing-mod--m-xl-5--108a3";
export var mXlAuto = "spacing-mod--m-xl-auto--8e6d5";
export var mXlN1 = "spacing-mod--m-xl-n1--63d27";
export var mXlN2 = "spacing-mod--m-xl-n2--c6617";
export var mXlN3 = "spacing-mod--m-xl-n3--c6991";
export var mXlN4 = "spacing-mod--m-xl-n4--945ea";
export var mXlN5 = "spacing-mod--m-xl-n5--b496d";
export var mb0 = "spacing-mod--mb-0--920e3";
export var mb1 = "spacing-mod--mb-1--4de58";
export var mb2 = "spacing-mod--mb-2--3ef08";
export var mb3 = "spacing-mod--mb-3--415df";
export var mb4 = "spacing-mod--mb-4--02cfb";
export var mb5 = "spacing-mod--mb-5--28400";
export var mbAuto = "spacing-mod--mb-auto--9063e";
export var mbLg0 = "spacing-mod--mb-lg-0--456b1";
export var mbLg1 = "spacing-mod--mb-lg-1--704b9";
export var mbLg2 = "spacing-mod--mb-lg-2--70b2f";
export var mbLg3 = "spacing-mod--mb-lg-3--39874";
export var mbLg4 = "spacing-mod--mb-lg-4--de6ee";
export var mbLg5 = "spacing-mod--mb-lg-5--c754c";
export var mbLgAuto = "spacing-mod--mb-lg-auto--82343";
export var mbLgN1 = "spacing-mod--mb-lg-n1--52729";
export var mbLgN2 = "spacing-mod--mb-lg-n2--dd184";
export var mbLgN3 = "spacing-mod--mb-lg-n3--39ace";
export var mbLgN4 = "spacing-mod--mb-lg-n4--e3ddd";
export var mbLgN5 = "spacing-mod--mb-lg-n5--b2a1d";
export var mbMd0 = "spacing-mod--mb-md-0--977d8";
export var mbMd1 = "spacing-mod--mb-md-1--a016b";
export var mbMd2 = "spacing-mod--mb-md-2--40c2b";
export var mbMd3 = "spacing-mod--mb-md-3--58fe2";
export var mbMd4 = "spacing-mod--mb-md-4--457c8";
export var mbMd5 = "spacing-mod--mb-md-5--96fb8";
export var mbMdAuto = "spacing-mod--mb-md-auto--1d5e7";
export var mbMdN1 = "spacing-mod--mb-md-n1--aa75a";
export var mbMdN2 = "spacing-mod--mb-md-n2--1338e";
export var mbMdN3 = "spacing-mod--mb-md-n3--6c516";
export var mbMdN4 = "spacing-mod--mb-md-n4--4f7fa";
export var mbMdN5 = "spacing-mod--mb-md-n5--dc733";
export var mbN1 = "spacing-mod--mb-n1--2bb88";
export var mbN2 = "spacing-mod--mb-n2--b6bec";
export var mbN3 = "spacing-mod--mb-n3--7b83f";
export var mbN4 = "spacing-mod--mb-n4--bfb44";
export var mbN5 = "spacing-mod--mb-n5--daa89";
export var mbSm0 = "spacing-mod--mb-sm-0--7e3b2";
export var mbSm1 = "spacing-mod--mb-sm-1--9a011";
export var mbSm2 = "spacing-mod--mb-sm-2--7de61";
export var mbSm3 = "spacing-mod--mb-sm-3--3ab38";
export var mbSm4 = "spacing-mod--mb-sm-4--57509";
export var mbSm5 = "spacing-mod--mb-sm-5--385d4";
export var mbSmAuto = "spacing-mod--mb-sm-auto--a0537";
export var mbSmN1 = "spacing-mod--mb-sm-n1--2f3e6";
export var mbSmN2 = "spacing-mod--mb-sm-n2--10d48";
export var mbSmN3 = "spacing-mod--mb-sm-n3--fe7d8";
export var mbSmN4 = "spacing-mod--mb-sm-n4--d8a96";
export var mbSmN5 = "spacing-mod--mb-sm-n5--85a57";
export var mbXl0 = "spacing-mod--mb-xl-0--958d5";
export var mbXl1 = "spacing-mod--mb-xl-1--207ab";
export var mbXl2 = "spacing-mod--mb-xl-2--b7431";
export var mbXl3 = "spacing-mod--mb-xl-3--6cd30";
export var mbXl4 = "spacing-mod--mb-xl-4--8ce1d";
export var mbXl5 = "spacing-mod--mb-xl-5--97448";
export var mbXlAuto = "spacing-mod--mb-xl-auto--c3d4f";
export var mbXlN1 = "spacing-mod--mb-xl-n1--3bb15";
export var mbXlN2 = "spacing-mod--mb-xl-n2--1eeb6";
export var mbXlN3 = "spacing-mod--mb-xl-n3--38ec3";
export var mbXlN4 = "spacing-mod--mb-xl-n4--ab0ca";
export var mbXlN5 = "spacing-mod--mb-xl-n5--bb9f1";
export var ml0 = "spacing-mod--ml-0--c663b";
export var ml1 = "spacing-mod--ml-1--a04eb";
export var ml2 = "spacing-mod--ml-2--4a61f";
export var ml3 = "spacing-mod--ml-3--72f67";
export var ml4 = "spacing-mod--ml-4--c6d99";
export var ml5 = "spacing-mod--ml-5--d3380";
export var mlAuto = "spacing-mod--ml-auto--07d78";
export var mlLg0 = "spacing-mod--ml-lg-0--007ef";
export var mlLg1 = "spacing-mod--ml-lg-1--4dccc";
export var mlLg2 = "spacing-mod--ml-lg-2--f6ec9";
export var mlLg3 = "spacing-mod--ml-lg-3--edbd8";
export var mlLg4 = "spacing-mod--ml-lg-4--a9809";
export var mlLg5 = "spacing-mod--ml-lg-5--ff59f";
export var mlLgAuto = "spacing-mod--ml-lg-auto--97ef0";
export var mlLgN1 = "spacing-mod--ml-lg-n1--74df7";
export var mlLgN2 = "spacing-mod--ml-lg-n2--2ee03";
export var mlLgN3 = "spacing-mod--ml-lg-n3--6e5da";
export var mlLgN4 = "spacing-mod--ml-lg-n4--8aec8";
export var mlLgN5 = "spacing-mod--ml-lg-n5--2014f";
export var mlMd0 = "spacing-mod--ml-md-0--eaf29";
export var mlMd1 = "spacing-mod--ml-md-1--c5141";
export var mlMd2 = "spacing-mod--ml-md-2--2c230";
export var mlMd3 = "spacing-mod--ml-md-3--9088d";
export var mlMd4 = "spacing-mod--ml-md-4--6afb6";
export var mlMd5 = "spacing-mod--ml-md-5--badbf";
export var mlMdAuto = "spacing-mod--ml-md-auto--3b805";
export var mlMdN1 = "spacing-mod--ml-md-n1--ae716";
export var mlMdN2 = "spacing-mod--ml-md-n2--0b774";
export var mlMdN3 = "spacing-mod--ml-md-n3--e8977";
export var mlMdN4 = "spacing-mod--ml-md-n4--05a32";
export var mlMdN5 = "spacing-mod--ml-md-n5--197a2";
export var mlN1 = "spacing-mod--ml-n1--48017";
export var mlN2 = "spacing-mod--ml-n2--d1a5c";
export var mlN3 = "spacing-mod--ml-n3--f2028";
export var mlN4 = "spacing-mod--ml-n4--4deae";
export var mlN5 = "spacing-mod--ml-n5--8aa38";
export var mlSm0 = "spacing-mod--ml-sm-0--7fe12";
export var mlSm1 = "spacing-mod--ml-sm-1--ed0a2";
export var mlSm2 = "spacing-mod--ml-sm-2--8f4ca";
export var mlSm3 = "spacing-mod--ml-sm-3--f5a75";
export var mlSm4 = "spacing-mod--ml-sm-4--c8934";
export var mlSm5 = "spacing-mod--ml-sm-5--7bb0a";
export var mlSmAuto = "spacing-mod--ml-sm-auto--d154f";
export var mlSmN1 = "spacing-mod--ml-sm-n1--8026a";
export var mlSmN2 = "spacing-mod--ml-sm-n2--9d834";
export var mlSmN3 = "spacing-mod--ml-sm-n3--29d9c";
export var mlSmN4 = "spacing-mod--ml-sm-n4--e4686";
export var mlSmN5 = "spacing-mod--ml-sm-n5--938d0";
export var mlXl0 = "spacing-mod--ml-xl-0--54f13";
export var mlXl1 = "spacing-mod--ml-xl-1--cc15d";
export var mlXl2 = "spacing-mod--ml-xl-2--26ebc";
export var mlXl3 = "spacing-mod--ml-xl-3--7816d";
export var mlXl4 = "spacing-mod--ml-xl-4--2bebf";
export var mlXl5 = "spacing-mod--ml-xl-5--ccb22";
export var mlXlAuto = "spacing-mod--ml-xl-auto--0cef8";
export var mlXlN1 = "spacing-mod--ml-xl-n1--b4b74";
export var mlXlN2 = "spacing-mod--ml-xl-n2--dfb29";
export var mlXlN3 = "spacing-mod--ml-xl-n3--2e1f2";
export var mlXlN4 = "spacing-mod--ml-xl-n4--3635d";
export var mlXlN5 = "spacing-mod--ml-xl-n5--e6be3";
export var mr0 = "spacing-mod--mr-0--1d08b";
export var mr1 = "spacing-mod--mr-1--430e4";
export var mr2 = "spacing-mod--mr-2--3ddd2";
export var mr3 = "spacing-mod--mr-3--e11c6";
export var mr4 = "spacing-mod--mr-4--21f25";
export var mr5 = "spacing-mod--mr-5--03808";
export var mrAuto = "spacing-mod--mr-auto--e4b08";
export var mrLg0 = "spacing-mod--mr-lg-0--180e0";
export var mrLg1 = "spacing-mod--mr-lg-1--42085";
export var mrLg2 = "spacing-mod--mr-lg-2--006e0";
export var mrLg3 = "spacing-mod--mr-lg-3--6916b";
export var mrLg4 = "spacing-mod--mr-lg-4--d9691";
export var mrLg5 = "spacing-mod--mr-lg-5--cabe2";
export var mrLgAuto = "spacing-mod--mr-lg-auto--4756a";
export var mrLgN1 = "spacing-mod--mr-lg-n1--c2796";
export var mrLgN2 = "spacing-mod--mr-lg-n2--93911";
export var mrLgN3 = "spacing-mod--mr-lg-n3--5946f";
export var mrLgN4 = "spacing-mod--mr-lg-n4--f2cc7";
export var mrLgN5 = "spacing-mod--mr-lg-n5--49c3d";
export var mrMd0 = "spacing-mod--mr-md-0--6b04b";
export var mrMd1 = "spacing-mod--mr-md-1--cb304";
export var mrMd2 = "spacing-mod--mr-md-2--86408";
export var mrMd3 = "spacing-mod--mr-md-3--3e7ff";
export var mrMd4 = "spacing-mod--mr-md-4--df314";
export var mrMd5 = "spacing-mod--mr-md-5--2c72e";
export var mrMdAuto = "spacing-mod--mr-md-auto--10f21";
export var mrMdN1 = "spacing-mod--mr-md-n1--f2638";
export var mrMdN2 = "spacing-mod--mr-md-n2--34f80";
export var mrMdN3 = "spacing-mod--mr-md-n3--34f9c";
export var mrMdN4 = "spacing-mod--mr-md-n4--e55a5";
export var mrMdN5 = "spacing-mod--mr-md-n5--eb0cf";
export var mrN1 = "spacing-mod--mr-n1--9af47";
export var mrN2 = "spacing-mod--mr-n2--1da66";
export var mrN3 = "spacing-mod--mr-n3--2b15a";
export var mrN4 = "spacing-mod--mr-n4--5c4d6";
export var mrN5 = "spacing-mod--mr-n5--332db";
export var mrSm0 = "spacing-mod--mr-sm-0--403a2";
export var mrSm1 = "spacing-mod--mr-sm-1--64beb";
export var mrSm2 = "spacing-mod--mr-sm-2--873d9";
export var mrSm3 = "spacing-mod--mr-sm-3--ff671";
export var mrSm4 = "spacing-mod--mr-sm-4--44aa5";
export var mrSm5 = "spacing-mod--mr-sm-5--41762";
export var mrSmAuto = "spacing-mod--mr-sm-auto--a12b9";
export var mrSmN1 = "spacing-mod--mr-sm-n1--b539c";
export var mrSmN2 = "spacing-mod--mr-sm-n2--f7efb";
export var mrSmN3 = "spacing-mod--mr-sm-n3--6279c";
export var mrSmN4 = "spacing-mod--mr-sm-n4--63210";
export var mrSmN5 = "spacing-mod--mr-sm-n5--42aa9";
export var mrXl0 = "spacing-mod--mr-xl-0--5c59a";
export var mrXl1 = "spacing-mod--mr-xl-1--d67e2";
export var mrXl2 = "spacing-mod--mr-xl-2--d9009";
export var mrXl3 = "spacing-mod--mr-xl-3--8a2a0";
export var mrXl4 = "spacing-mod--mr-xl-4--b5257";
export var mrXl5 = "spacing-mod--mr-xl-5--dd779";
export var mrXlAuto = "spacing-mod--mr-xl-auto--e6ff8";
export var mrXlN1 = "spacing-mod--mr-xl-n1--00c2a";
export var mrXlN2 = "spacing-mod--mr-xl-n2--8a3ba";
export var mrXlN3 = "spacing-mod--mr-xl-n3--7d63a";
export var mrXlN4 = "spacing-mod--mr-xl-n4--0d215";
export var mrXlN5 = "spacing-mod--mr-xl-n5--23e73";
export var mt0 = "spacing-mod--mt-0--5a489";
export var mt1 = "spacing-mod--mt-1--0177b";
export var mt2 = "spacing-mod--mt-2--5333e";
export var mt3 = "spacing-mod--mt-3--a610d";
export var mt4 = "spacing-mod--mt-4--39a4b";
export var mt5 = "spacing-mod--mt-5--10247";
export var mtAuto = "spacing-mod--mt-auto--54e5e";
export var mtLg0 = "spacing-mod--mt-lg-0--51684";
export var mtLg1 = "spacing-mod--mt-lg-1--87bd9";
export var mtLg2 = "spacing-mod--mt-lg-2--160c1";
export var mtLg3 = "spacing-mod--mt-lg-3--96f65";
export var mtLg4 = "spacing-mod--mt-lg-4--bcb39";
export var mtLg5 = "spacing-mod--mt-lg-5--0fbb9";
export var mtLgAuto = "spacing-mod--mt-lg-auto--eecc1";
export var mtLgN1 = "spacing-mod--mt-lg-n1--60aaa";
export var mtLgN2 = "spacing-mod--mt-lg-n2--6f471";
export var mtLgN3 = "spacing-mod--mt-lg-n3--fbad5";
export var mtLgN4 = "spacing-mod--mt-lg-n4--f712f";
export var mtLgN5 = "spacing-mod--mt-lg-n5--f90c0";
export var mtMd0 = "spacing-mod--mt-md-0--d2bf3";
export var mtMd1 = "spacing-mod--mt-md-1--e2479";
export var mtMd2 = "spacing-mod--mt-md-2--ca804";
export var mtMd3 = "spacing-mod--mt-md-3--b25fb";
export var mtMd4 = "spacing-mod--mt-md-4--37cab";
export var mtMd5 = "spacing-mod--mt-md-5--6165c";
export var mtMdAuto = "spacing-mod--mt-md-auto--4fb41";
export var mtMdN1 = "spacing-mod--mt-md-n1--b5167";
export var mtMdN2 = "spacing-mod--mt-md-n2--bc140";
export var mtMdN3 = "spacing-mod--mt-md-n3--9f793";
export var mtMdN4 = "spacing-mod--mt-md-n4--c1510";
export var mtMdN5 = "spacing-mod--mt-md-n5--e9d98";
export var mtN1 = "spacing-mod--mt-n1--1e638";
export var mtN2 = "spacing-mod--mt-n2--bcf24";
export var mtN3 = "spacing-mod--mt-n3--9d45a";
export var mtN4 = "spacing-mod--mt-n4--643a4";
export var mtN5 = "spacing-mod--mt-n5--93e59";
export var mtSm0 = "spacing-mod--mt-sm-0--9e740";
export var mtSm1 = "spacing-mod--mt-sm-1--83da1";
export var mtSm2 = "spacing-mod--mt-sm-2--665aa";
export var mtSm3 = "spacing-mod--mt-sm-3--dc74b";
export var mtSm4 = "spacing-mod--mt-sm-4--de6e3";
export var mtSm5 = "spacing-mod--mt-sm-5--73485";
export var mtSmAuto = "spacing-mod--mt-sm-auto--71264";
export var mtSmN1 = "spacing-mod--mt-sm-n1--93e84";
export var mtSmN2 = "spacing-mod--mt-sm-n2--da5b9";
export var mtSmN3 = "spacing-mod--mt-sm-n3--7c48e";
export var mtSmN4 = "spacing-mod--mt-sm-n4--496c6";
export var mtSmN5 = "spacing-mod--mt-sm-n5--197fa";
export var mtXl0 = "spacing-mod--mt-xl-0--31ccb";
export var mtXl1 = "spacing-mod--mt-xl-1--2f334";
export var mtXl2 = "spacing-mod--mt-xl-2--e7473";
export var mtXl3 = "spacing-mod--mt-xl-3--f98ad";
export var mtXl4 = "spacing-mod--mt-xl-4--3de74";
export var mtXl5 = "spacing-mod--mt-xl-5--d0bcd";
export var mtXlAuto = "spacing-mod--mt-xl-auto--1c3ce";
export var mtXlN1 = "spacing-mod--mt-xl-n1--15ce3";
export var mtXlN2 = "spacing-mod--mt-xl-n2--56e0d";
export var mtXlN3 = "spacing-mod--mt-xl-n3--6cc1c";
export var mtXlN4 = "spacing-mod--mt-xl-n4--891dd";
export var mtXlN5 = "spacing-mod--mt-xl-n5--2886c";
export var mx0 = "spacing-mod--mx-0--2bb32";
export var mx1 = "spacing-mod--mx-1--d7f34";
export var mx2 = "spacing-mod--mx-2--b1285";
export var mx3 = "spacing-mod--mx-3--48a5d";
export var mx4 = "spacing-mod--mx-4--8f601";
export var mx5 = "spacing-mod--mx-5--bea95";
export var mxAuto = "spacing-mod--mx-auto--f3719";
export var mxLg0 = "spacing-mod--mx-lg-0--32178";
export var mxLg1 = "spacing-mod--mx-lg-1--95126";
export var mxLg2 = "spacing-mod--mx-lg-2--03678";
export var mxLg3 = "spacing-mod--mx-lg-3--297ec";
export var mxLg4 = "spacing-mod--mx-lg-4--08b12";
export var mxLg5 = "spacing-mod--mx-lg-5--41441";
export var mxLgAuto = "spacing-mod--mx-lg-auto--2bbeb";
export var mxLgN1 = "spacing-mod--mx-lg-n1--8e93f";
export var mxLgN2 = "spacing-mod--mx-lg-n2--d5dc2";
export var mxLgN3 = "spacing-mod--mx-lg-n3--98e10";
export var mxLgN4 = "spacing-mod--mx-lg-n4--625df";
export var mxLgN5 = "spacing-mod--mx-lg-n5--9a9db";
export var mxMd0 = "spacing-mod--mx-md-0--9049c";
export var mxMd1 = "spacing-mod--mx-md-1--1013f";
export var mxMd2 = "spacing-mod--mx-md-2--64c02";
export var mxMd3 = "spacing-mod--mx-md-3--4f6a7";
export var mxMd4 = "spacing-mod--mx-md-4--e7d91";
export var mxMd5 = "spacing-mod--mx-md-5--f896f";
export var mxMdAuto = "spacing-mod--mx-md-auto--42ef7";
export var mxMdN1 = "spacing-mod--mx-md-n1--52725";
export var mxMdN2 = "spacing-mod--mx-md-n2--d16ca";
export var mxMdN3 = "spacing-mod--mx-md-n3--35396";
export var mxMdN4 = "spacing-mod--mx-md-n4--cb599";
export var mxMdN5 = "spacing-mod--mx-md-n5--dc372";
export var mxN1 = "spacing-mod--mx-n1--c6bfb";
export var mxN2 = "spacing-mod--mx-n2--dfa4d";
export var mxN3 = "spacing-mod--mx-n3--946e2";
export var mxN4 = "spacing-mod--mx-n4--c3215";
export var mxN5 = "spacing-mod--mx-n5--eb8f0";
export var mxSm0 = "spacing-mod--mx-sm-0--19781";
export var mxSm1 = "spacing-mod--mx-sm-1--4ae01";
export var mxSm2 = "spacing-mod--mx-sm-2--ac54a";
export var mxSm3 = "spacing-mod--mx-sm-3--165d5";
export var mxSm4 = "spacing-mod--mx-sm-4--2bb78";
export var mxSm5 = "spacing-mod--mx-sm-5--66a31";
export var mxSmAuto = "spacing-mod--mx-sm-auto--b4756";
export var mxSmN1 = "spacing-mod--mx-sm-n1--0c66c";
export var mxSmN2 = "spacing-mod--mx-sm-n2--dff03";
export var mxSmN3 = "spacing-mod--mx-sm-n3--813d0";
export var mxSmN4 = "spacing-mod--mx-sm-n4--1373a";
export var mxSmN5 = "spacing-mod--mx-sm-n5--bebe0";
export var mxXl0 = "spacing-mod--mx-xl-0--0ccf2";
export var mxXl1 = "spacing-mod--mx-xl-1--562e6";
export var mxXl2 = "spacing-mod--mx-xl-2--87b38";
export var mxXl3 = "spacing-mod--mx-xl-3--b9ba4";
export var mxXl4 = "spacing-mod--mx-xl-4--c2247";
export var mxXl5 = "spacing-mod--mx-xl-5--39f8f";
export var mxXlAuto = "spacing-mod--mx-xl-auto--933f6";
export var mxXlN1 = "spacing-mod--mx-xl-n1--1ef2a";
export var mxXlN2 = "spacing-mod--mx-xl-n2--c1334";
export var mxXlN3 = "spacing-mod--mx-xl-n3--56513";
export var mxXlN4 = "spacing-mod--mx-xl-n4--4567d";
export var mxXlN5 = "spacing-mod--mx-xl-n5--d948a";
export var my0 = "spacing-mod--my-0--456df";
export var my1 = "spacing-mod--my-1--3fd17";
export var my2 = "spacing-mod--my-2--b842f";
export var my3 = "spacing-mod--my-3--3f576";
export var my4 = "spacing-mod--my-4--dfbfd";
export var my5 = "spacing-mod--my-5--fe101";
export var myAuto = "spacing-mod--my-auto--2d97b";
export var myLg0 = "spacing-mod--my-lg-0--2405f";
export var myLg1 = "spacing-mod--my-lg-1--b1ff7";
export var myLg2 = "spacing-mod--my-lg-2--cce64";
export var myLg3 = "spacing-mod--my-lg-3--4d6ff";
export var myLg4 = "spacing-mod--my-lg-4--0f66b";
export var myLg5 = "spacing-mod--my-lg-5--b9a2f";
export var myLgAuto = "spacing-mod--my-lg-auto--734fc";
export var myLgN1 = "spacing-mod--my-lg-n1--fa943";
export var myLgN2 = "spacing-mod--my-lg-n2--45186";
export var myLgN3 = "spacing-mod--my-lg-n3--8cc73";
export var myLgN4 = "spacing-mod--my-lg-n4--f9822";
export var myLgN5 = "spacing-mod--my-lg-n5--ed0d3";
export var myMd0 = "spacing-mod--my-md-0--30e69";
export var myMd1 = "spacing-mod--my-md-1--87fea";
export var myMd2 = "spacing-mod--my-md-2--cd0f4";
export var myMd3 = "spacing-mod--my-md-3--4662e";
export var myMd4 = "spacing-mod--my-md-4--0ca4b";
export var myMd5 = "spacing-mod--my-md-5--115ba";
export var myMdAuto = "spacing-mod--my-md-auto--57bfe";
export var myMdN1 = "spacing-mod--my-md-n1--7f1ed";
export var myMdN2 = "spacing-mod--my-md-n2--f500c";
export var myMdN3 = "spacing-mod--my-md-n3--c7b3a";
export var myMdN4 = "spacing-mod--my-md-n4--87325";
export var myMdN5 = "spacing-mod--my-md-n5--a4664";
export var myN1 = "spacing-mod--my-n1--a87de";
export var myN2 = "spacing-mod--my-n2--c6568";
export var myN3 = "spacing-mod--my-n3--d5c2d";
export var myN4 = "spacing-mod--my-n4--25cf9";
export var myN5 = "spacing-mod--my-n5--47a2e";
export var mySm0 = "spacing-mod--my-sm-0--5d77c";
export var mySm1 = "spacing-mod--my-sm-1--e1e77";
export var mySm2 = "spacing-mod--my-sm-2--57ecf";
export var mySm3 = "spacing-mod--my-sm-3--45ecc";
export var mySm4 = "spacing-mod--my-sm-4--08698";
export var mySm5 = "spacing-mod--my-sm-5--df98d";
export var mySmAuto = "spacing-mod--my-sm-auto--02782";
export var mySmN1 = "spacing-mod--my-sm-n1--ba710";
export var mySmN2 = "spacing-mod--my-sm-n2--7b31f";
export var mySmN3 = "spacing-mod--my-sm-n3--71776";
export var mySmN4 = "spacing-mod--my-sm-n4--0b287";
export var mySmN5 = "spacing-mod--my-sm-n5--33c0a";
export var myXl0 = "spacing-mod--my-xl-0--902d8";
export var myXl1 = "spacing-mod--my-xl-1--d050a";
export var myXl2 = "spacing-mod--my-xl-2--fdd24";
export var myXl3 = "spacing-mod--my-xl-3--9413e";
export var myXl4 = "spacing-mod--my-xl-4--ff54a";
export var myXl5 = "spacing-mod--my-xl-5--a738f";
export var myXlAuto = "spacing-mod--my-xl-auto--32936";
export var myXlN1 = "spacing-mod--my-xl-n1--13b34";
export var myXlN2 = "spacing-mod--my-xl-n2--47720";
export var myXlN3 = "spacing-mod--my-xl-n3--bf0dd";
export var myXlN4 = "spacing-mod--my-xl-n4--d2f9c";
export var myXlN5 = "spacing-mod--my-xl-n5--6a8a3";
export var p0 = "spacing-mod--p-0--a2b91";
export var p1 = "spacing-mod--p-1--8a9b8";
export var p2 = "spacing-mod--p-2--3ab7a";
export var p3 = "spacing-mod--p-3--b465d";
export var p4 = "spacing-mod--p-4--0e643";
export var p5 = "spacing-mod--p-5--54e31";
export var pLg0 = "spacing-mod--p-lg-0--a7b9a";
export var pLg1 = "spacing-mod--p-lg-1--19f3f";
export var pLg2 = "spacing-mod--p-lg-2--d1b90";
export var pLg3 = "spacing-mod--p-lg-3--a8910";
export var pLg4 = "spacing-mod--p-lg-4--1df91";
export var pLg5 = "spacing-mod--p-lg-5--87283";
export var pMd0 = "spacing-mod--p-md-0--b865b";
export var pMd1 = "spacing-mod--p-md-1--cf538";
export var pMd2 = "spacing-mod--p-md-2--faabc";
export var pMd3 = "spacing-mod--p-md-3--b27ba";
export var pMd4 = "spacing-mod--p-md-4--10921";
export var pMd5 = "spacing-mod--p-md-5--71007";
export var pSm0 = "spacing-mod--p-sm-0--5f3b4";
export var pSm1 = "spacing-mod--p-sm-1--dde44";
export var pSm2 = "spacing-mod--p-sm-2--c1eee";
export var pSm3 = "spacing-mod--p-sm-3--63554";
export var pSm4 = "spacing-mod--p-sm-4--c6e53";
export var pSm5 = "spacing-mod--p-sm-5--e1b75";
export var pXl0 = "spacing-mod--p-xl-0--3383f";
export var pXl1 = "spacing-mod--p-xl-1--acb13";
export var pXl2 = "spacing-mod--p-xl-2--80172";
export var pXl3 = "spacing-mod--p-xl-3--d587b";
export var pXl4 = "spacing-mod--p-xl-4--f3cc3";
export var pXl5 = "spacing-mod--p-xl-5--9c167";
export var pb0 = "spacing-mod--pb-0--52966";
export var pb1 = "spacing-mod--pb-1--ae06f";
export var pb2 = "spacing-mod--pb-2--2b2e7";
export var pb3 = "spacing-mod--pb-3--6a3ee";
export var pb4 = "spacing-mod--pb-4--c3275";
export var pb5 = "spacing-mod--pb-5--8f148";
export var pbLg0 = "spacing-mod--pb-lg-0--36579";
export var pbLg1 = "spacing-mod--pb-lg-1--9aecb";
export var pbLg2 = "spacing-mod--pb-lg-2--75b5a";
export var pbLg3 = "spacing-mod--pb-lg-3--d5856";
export var pbLg4 = "spacing-mod--pb-lg-4--98618";
export var pbLg5 = "spacing-mod--pb-lg-5--c63c3";
export var pbMd0 = "spacing-mod--pb-md-0--19e3b";
export var pbMd1 = "spacing-mod--pb-md-1--7ccbc";
export var pbMd2 = "spacing-mod--pb-md-2--71703";
export var pbMd3 = "spacing-mod--pb-md-3--36738";
export var pbMd4 = "spacing-mod--pb-md-4--55f96";
export var pbMd5 = "spacing-mod--pb-md-5--12186";
export var pbSm0 = "spacing-mod--pb-sm-0--a9a91";
export var pbSm1 = "spacing-mod--pb-sm-1--209df";
export var pbSm2 = "spacing-mod--pb-sm-2--7b7ec";
export var pbSm3 = "spacing-mod--pb-sm-3--e5820";
export var pbSm4 = "spacing-mod--pb-sm-4--b97d9";
export var pbSm5 = "spacing-mod--pb-sm-5--61b32";
export var pbXl0 = "spacing-mod--pb-xl-0--570d5";
export var pbXl1 = "spacing-mod--pb-xl-1--ad761";
export var pbXl2 = "spacing-mod--pb-xl-2--a7118";
export var pbXl3 = "spacing-mod--pb-xl-3--dc831";
export var pbXl4 = "spacing-mod--pb-xl-4--62469";
export var pbXl5 = "spacing-mod--pb-xl-5--4a51d";
export var pl0 = "spacing-mod--pl-0--d44ef";
export var pl1 = "spacing-mod--pl-1--a0182";
export var pl2 = "spacing-mod--pl-2--fe3b2";
export var pl3 = "spacing-mod--pl-3--a4ce6";
export var pl4 = "spacing-mod--pl-4--bdd51";
export var pl5 = "spacing-mod--pl-5--1a235";
export var plLg0 = "spacing-mod--pl-lg-0--070c0";
export var plLg1 = "spacing-mod--pl-lg-1--c7e68";
export var plLg2 = "spacing-mod--pl-lg-2--3840e";
export var plLg3 = "spacing-mod--pl-lg-3--f1e58";
export var plLg4 = "spacing-mod--pl-lg-4--f0b54";
export var plLg5 = "spacing-mod--pl-lg-5--09e15";
export var plMd0 = "spacing-mod--pl-md-0--05f44";
export var plMd1 = "spacing-mod--pl-md-1--548c1";
export var plMd2 = "spacing-mod--pl-md-2--71ffe";
export var plMd3 = "spacing-mod--pl-md-3--1c456";
export var plMd4 = "spacing-mod--pl-md-4--7095c";
export var plMd5 = "spacing-mod--pl-md-5--abff5";
export var plSm0 = "spacing-mod--pl-sm-0--4a8a1";
export var plSm1 = "spacing-mod--pl-sm-1--63ecc";
export var plSm2 = "spacing-mod--pl-sm-2--f19fe";
export var plSm3 = "spacing-mod--pl-sm-3--dd8fd";
export var plSm4 = "spacing-mod--pl-sm-4--dd216";
export var plSm5 = "spacing-mod--pl-sm-5--cc022";
export var plXl0 = "spacing-mod--pl-xl-0--6eb7c";
export var plXl1 = "spacing-mod--pl-xl-1--d7536";
export var plXl2 = "spacing-mod--pl-xl-2--758c3";
export var plXl3 = "spacing-mod--pl-xl-3--ec473";
export var plXl4 = "spacing-mod--pl-xl-4--2184a";
export var plXl5 = "spacing-mod--pl-xl-5--a7b65";
export var pr0 = "spacing-mod--pr-0--d88d9";
export var pr1 = "spacing-mod--pr-1--9d826";
export var pr2 = "spacing-mod--pr-2--786e3";
export var pr3 = "spacing-mod--pr-3--11ea9";
export var pr4 = "spacing-mod--pr-4--8fbe6";
export var pr5 = "spacing-mod--pr-5--2c7df";
export var prLg0 = "spacing-mod--pr-lg-0--36768";
export var prLg1 = "spacing-mod--pr-lg-1--85007";
export var prLg2 = "spacing-mod--pr-lg-2--cea90";
export var prLg3 = "spacing-mod--pr-lg-3--1f596";
export var prLg4 = "spacing-mod--pr-lg-4--25cec";
export var prLg5 = "spacing-mod--pr-lg-5--feefd";
export var prMd0 = "spacing-mod--pr-md-0--6531e";
export var prMd1 = "spacing-mod--pr-md-1--321d6";
export var prMd2 = "spacing-mod--pr-md-2--4bf9c";
export var prMd3 = "spacing-mod--pr-md-3--a0c3a";
export var prMd4 = "spacing-mod--pr-md-4--75435";
export var prMd5 = "spacing-mod--pr-md-5--de200";
export var prSm0 = "spacing-mod--pr-sm-0--ca2db";
export var prSm1 = "spacing-mod--pr-sm-1--66625";
export var prSm2 = "spacing-mod--pr-sm-2--fec07";
export var prSm3 = "spacing-mod--pr-sm-3--c2016";
export var prSm4 = "spacing-mod--pr-sm-4--d9134";
export var prSm5 = "spacing-mod--pr-sm-5--f2a11";
export var prXl0 = "spacing-mod--pr-xl-0--7d5d4";
export var prXl1 = "spacing-mod--pr-xl-1--671b6";
export var prXl2 = "spacing-mod--pr-xl-2--81da0";
export var prXl3 = "spacing-mod--pr-xl-3--a6b8d";
export var prXl4 = "spacing-mod--pr-xl-4--645ac";
export var prXl5 = "spacing-mod--pr-xl-5--8f6cc";
export var pt0 = "spacing-mod--pt-0--be5e3";
export var pt1 = "spacing-mod--pt-1--e17ba";
export var pt2 = "spacing-mod--pt-2--66f9e";
export var pt3 = "spacing-mod--pt-3--1e597";
export var pt4 = "spacing-mod--pt-4--80c64";
export var pt5 = "spacing-mod--pt-5--f871d";
export var ptLg0 = "spacing-mod--pt-lg-0--05c24";
export var ptLg1 = "spacing-mod--pt-lg-1--07c0d";
export var ptLg2 = "spacing-mod--pt-lg-2--d2f14";
export var ptLg3 = "spacing-mod--pt-lg-3--9b9c2";
export var ptLg4 = "spacing-mod--pt-lg-4--9aab5";
export var ptLg5 = "spacing-mod--pt-lg-5--0c77d";
export var ptMd0 = "spacing-mod--pt-md-0--4a9c8";
export var ptMd1 = "spacing-mod--pt-md-1--1b02a";
export var ptMd2 = "spacing-mod--pt-md-2--d6601";
export var ptMd3 = "spacing-mod--pt-md-3--328e6";
export var ptMd4 = "spacing-mod--pt-md-4--c3a66";
export var ptMd5 = "spacing-mod--pt-md-5--9ee2e";
export var ptSm0 = "spacing-mod--pt-sm-0--6dda4";
export var ptSm1 = "spacing-mod--pt-sm-1--07f46";
export var ptSm2 = "spacing-mod--pt-sm-2--9f500";
export var ptSm3 = "spacing-mod--pt-sm-3--e3a56";
export var ptSm4 = "spacing-mod--pt-sm-4--eea2e";
export var ptSm5 = "spacing-mod--pt-sm-5--1dbdc";
export var ptXl0 = "spacing-mod--pt-xl-0--48a95";
export var ptXl1 = "spacing-mod--pt-xl-1--58bc2";
export var ptXl2 = "spacing-mod--pt-xl-2--fc383";
export var ptXl3 = "spacing-mod--pt-xl-3--5d2f6";
export var ptXl4 = "spacing-mod--pt-xl-4--6a90c";
export var ptXl5 = "spacing-mod--pt-xl-5--59dfe";
export var px0 = "spacing-mod--px-0--a2e54";
export var px1 = "spacing-mod--px-1--3ecdf";
export var px2 = "spacing-mod--px-2--9ec43";
export var px3 = "spacing-mod--px-3--0a4a7";
export var px4 = "spacing-mod--px-4--6bd91";
export var px5 = "spacing-mod--px-5--69d6c";
export var pxLg0 = "spacing-mod--px-lg-0--ea149";
export var pxLg1 = "spacing-mod--px-lg-1--5f6bf";
export var pxLg2 = "spacing-mod--px-lg-2--4f576";
export var pxLg3 = "spacing-mod--px-lg-3--07709";
export var pxLg4 = "spacing-mod--px-lg-4--edcd0";
export var pxLg5 = "spacing-mod--px-lg-5--e2fe2";
export var pxMd0 = "spacing-mod--px-md-0--d79ac";
export var pxMd1 = "spacing-mod--px-md-1--371bc";
export var pxMd2 = "spacing-mod--px-md-2--0d886";
export var pxMd3 = "spacing-mod--px-md-3--8a26d";
export var pxMd4 = "spacing-mod--px-md-4--ed08f";
export var pxMd5 = "spacing-mod--px-md-5--aaf1f";
export var pxSm0 = "spacing-mod--px-sm-0--91bda";
export var pxSm1 = "spacing-mod--px-sm-1--6268e";
export var pxSm2 = "spacing-mod--px-sm-2--7393b";
export var pxSm3 = "spacing-mod--px-sm-3--296a5";
export var pxSm4 = "spacing-mod--px-sm-4--d343e";
export var pxSm5 = "spacing-mod--px-sm-5--7a34a";
export var pxXl0 = "spacing-mod--px-xl-0--8c092";
export var pxXl1 = "spacing-mod--px-xl-1--edba0";
export var pxXl2 = "spacing-mod--px-xl-2--ca525";
export var pxXl3 = "spacing-mod--px-xl-3--0631b";
export var pxXl4 = "spacing-mod--px-xl-4--8a335";
export var pxXl5 = "spacing-mod--px-xl-5--b465f";
export var py0 = "spacing-mod--py-0--0db75";
export var py1 = "spacing-mod--py-1--04321";
export var py2 = "spacing-mod--py-2--deda8";
export var py3 = "spacing-mod--py-3--128a7";
export var py4 = "spacing-mod--py-4--60eea";
export var py5 = "spacing-mod--py-5--be9e0";
export var pyLg0 = "spacing-mod--py-lg-0--1b8c0";
export var pyLg1 = "spacing-mod--py-lg-1--9724f";
export var pyLg2 = "spacing-mod--py-lg-2--5f3f6";
export var pyLg3 = "spacing-mod--py-lg-3--34ca2";
export var pyLg4 = "spacing-mod--py-lg-4--4610c";
export var pyLg5 = "spacing-mod--py-lg-5--2a4d4";
export var pyMd0 = "spacing-mod--py-md-0--c20d9";
export var pyMd1 = "spacing-mod--py-md-1--191b5";
export var pyMd2 = "spacing-mod--py-md-2--2ee22";
export var pyMd3 = "spacing-mod--py-md-3--064f3";
export var pyMd4 = "spacing-mod--py-md-4--548f9";
export var pyMd5 = "spacing-mod--py-md-5--7e63a";
export var pySm0 = "spacing-mod--py-sm-0--01a3c";
export var pySm1 = "spacing-mod--py-sm-1--08c9d";
export var pySm2 = "spacing-mod--py-sm-2--7f78c";
export var pySm3 = "spacing-mod--py-sm-3--97203";
export var pySm4 = "spacing-mod--py-sm-4--f0986";
export var pySm5 = "spacing-mod--py-sm-5--83fca";
export var pyXl0 = "spacing-mod--py-xl-0--ae377";
export var pyXl1 = "spacing-mod--py-xl-1--7e318";
export var pyXl2 = "spacing-mod--py-xl-2--d0f82";
export var pyXl3 = "spacing-mod--py-xl-3--3ba34";
export var pyXl4 = "spacing-mod--py-xl-4--35fcc";
export var pyXl5 = "spacing-mod--py-xl-5--3e7b8";