// extracted by mini-css-extract-plugin
export var blockquote = "type-mod--blockquote--b102b";
export var blockquoteFooter = "type-mod--blockquote-footer--ba746";
export var display1 = "type-mod--display-1--2894c";
export var display2 = "type-mod--display-2--5e07a";
export var display3 = "type-mod--display-3--04061";
export var display4 = "type-mod--display-4--d78c5";
export var h1 = "type-mod--h1--80da9";
export var h2 = "type-mod--h2--45187";
export var h3 = "type-mod--h3--6a208";
export var h4 = "type-mod--h4--8b2bf";
export var h5 = "type-mod--h5--ead8c";
export var h6 = "type-mod--h6--482cc";
export var initialism = "type-mod--initialism--de298";
export var lead = "type-mod--lead--d29f5";
export var listInline = "type-mod--list-inline--7dabb";
export var listInlineItem = "type-mod--list-inline-item--09ccc";
export var listUnstyled = "type-mod--list-unstyled--2f0d5";
export var mark = "type-mod--mark--4347e";
export var small = "type-mod--small--8a870";