// extracted by mini-css-extract-plugin
export var fontItalic = "text-mod--font-italic--20ca4";
export var fontWeightBold = "text-mod--font-weight-bold--22126";
export var fontWeightBolder = "text-mod--font-weight-bolder--d051a";
export var fontWeightLight = "text-mod--font-weight-light--67edb";
export var fontWeightLighter = "text-mod--font-weight-lighter--bfee3";
export var fontWeightNormal = "text-mod--font-weight-normal--cca85";
export var textBlack50 = "text-mod--text-black-50--2fb4c";
export var textBody = "text-mod--text-body--f4062";
export var textBreak = "text-mod--text-break--93657";
export var textCapitalize = "text-mod--text-capitalize--8c229";
export var textCenter = "text-mod--text-center--2ece3";
export var textDanger = "text-mod--text-danger--aa4ca";
export var textDark = "text-mod--text-dark--a0cdb";
export var textDecorationNone = "text-mod--text-decoration-none--572f8";
export var textHide = "text-mod--text-hide--d3d88";
export var textInfo = "text-mod--text-info--88a6d";
export var textJustify = "text-mod--text-justify--d2202";
export var textLeft = "text-mod--text-left--02e0d";
export var textLgCenter = "text-mod--text-lg-center--9399e";
export var textLgLeft = "text-mod--text-lg-left--3d695";
export var textLgRight = "text-mod--text-lg-right--a3e2e";
export var textLight = "text-mod--text-light--b18ef";
export var textLowercase = "text-mod--text-lowercase--b1a93";
export var textMdCenter = "text-mod--text-md-center--6a436";
export var textMdLeft = "text-mod--text-md-left--a637e";
export var textMdRight = "text-mod--text-md-right--347d5";
export var textMonospace = "text-mod--text-monospace--9e780";
export var textMuted = "text-mod--text-muted--a034d";
export var textNowrap = "text-mod--text-nowrap--ad099";
export var textPrimary = "text-mod--text-primary--00a74";
export var textReset = "text-mod--text-reset--51b8b";
export var textRight = "text-mod--text-right--88b30";
export var textSecondary = "text-mod--text-secondary--7f1b6";
export var textSmCenter = "text-mod--text-sm-center--a5f4e";
export var textSmLeft = "text-mod--text-sm-left--4f32c";
export var textSmRight = "text-mod--text-sm-right--ffd5f";
export var textSuccess = "text-mod--text-success--e0344";
export var textTruncate = "text-mod--text-truncate--16b7d";
export var textUppercase = "text-mod--text-uppercase--2904d";
export var textWarning = "text-mod--text-warning--b91a5";
export var textWhite = "text-mod--text-white--6f21d";
export var textWhite50 = "text-mod--text-white-50--ec1be";
export var textWrap = "text-mod--text-wrap--7832c";
export var textXlCenter = "text-mod--text-xl-center--c8827";
export var textXlLeft = "text-mod--text-xl-left--2ea40";
export var textXlRight = "text-mod--text-xl-right--343d8";